/* Universal Stylings */
body {
  min-height: 100vh;
  margin: 0;
  background-color: #474F57;
  background-image:linear-gradient(to bottom, #66727D, #474F57) ;
  background-repeat: no-repeat;
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
  scroll-behavior: smooth;
}
a{
  text-decoration: none;
}
.card{
  border-radius: 1.5rem;
  box-shadow: 2px 7px 15px -2px rgb(8, 19, 30);
  background-color: #16222c;
  color: #CFE6FC;
}
.logo{
  width: auto;
  height: 3em;
  position: absolute;
  left: 4em;
  top: 2.5em;
  filter: drop-shadow(0 0 1rem #282c31);
}
.navcontainer{
  display: block;
  width: 60vw;
  height: 10em;
  transition: all 0.5s;
}
.navbar{
  display: flex;
  justify-content: center;
  margin-top: 2em;
  border-radius: 1em;
  height: 5em;
  background-color: rgba(	155, 172, 189, 0.3);
  filter: drop-shadow(0 0 1rem #282c31);
}
.navbtn{
  font-size: 1.2rem;
  height: 3em;
  width: 13vw;
  background-color: #474F57;
  border-color: #474F57;
  transition: all 0.5s;
}
.navimg{
  height: .75em;
  width: .75em;
  margin-bottom: 2px;
}
.home{
  font-weight: 200 !important;
  padding: 2em;
  color: #CFE6FC;
}
.home-header{
  font-weight: 100;
  color: #CFE6FC;
}
.home-text{
  margin-top: 0.2em;
  font-size: 1.2em;
  font-weight: 200;
  filter: drop-shadow(0 0 .75rem #141618);
}
.home-text a{
  text-decoration: none;
}
.logohome{
  width: auto;
  height: 14em;
  margin-left: 4em;
  filter: drop-shadow(0 0 1rem #282c31);
  transition: all 0.75s;
  animation-delay: 1s;
  -webkit-animation: spread1 1.5s ease;
  -moz-animation: spread1 1.5s ease;
  animation: spread1 1.5s ease;
}
.x{
  height: 4em;
  margin-top: 5em;
  margin-bottom: 5em;
  margin-left: 3em;
  margin-right: 1em;
  transition: all 0.75s;
  animation-delay: 1s;
  -webkit-animation: spread2 1.5s ease;
  -moz-animation: spread2 1.5s ease;
  animation: spread2 1.5s ease;
}
.ebay{
  height: 7em;
  margin-top: 3em;
  margin-bottom: 3em;
  margin-left: 2em;
  margin-right: 1em;
  filter: drop-shadow(0 0 1rem #282c31);
  transition: all 0.75s;
  animation-delay: 1s;
  -webkit-animation: spread3 1.5s ease;
  -moz-animation: spread3 1.5s ease;
  animation: spread3 1.5s ease;
}
.link{
  color:#CFE6FC;
  transition: all 0.5s;
}
.link:hover{
  color: #DE1F38;
}
.socials{
  height: 25px;
  width: 25px;
  margin: 1em;
  fill: #CFE6FC;
  transition: all 0.5s;
}
.socials:hover{
  fill:#DE1F38;
}
.navactive {
  background-color: #DE1F38;
  border-color: #DE1F38;
}
.info-btn {
  margin-top: 5em;
  margin-bottom: 5em;
  height: 3em;
  position: relative;
  padding: 10px 20px;
  border-radius: 7px;
  border: 1px solid #2AB0E9;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 2px;
  background: transparent;
  color: #CFE6FC;
  overflow: hidden;
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.info-btn:hover {
  border-color:  rgb(33, 172, 231);
  background: rgb(33, 172, 231);
  box-shadow: 0 0 30px 5px rgba(0, 142, 236, 0.815);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.info-btn:hover::before {
  -webkit-animation: sh02 0.75s 0s linear;
  -moz-animation: sh02 0.75s 0s linear;
  animation: sh02 0.75s 0s linear;
}
.info-btn::before {
  content: '';
  display: block;
  width: 0px;
  height: 86%;
  position: absolute;
  top: 7%;
  left: 0%;
  opacity: 0;
  background: #fff;
  box-shadow: 0 0 50px 30px #fff;
  -webkit-transform: skewX(-20deg);
  -moz-transform: skewX(-20deg);
  -ms-transform: skewX(-20deg);
  -o-transform: skewX(-20deg);
  transform: skewX(-20deg);
}
.info-btn:active {
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
}
.card-info {
  background-color: #474F57;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40vw;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 32px;
  overflow: hidden;
  border-radius: 1rem;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
}
.info-cards{
  border-radius: 10px;
  background-color: rgba(29,44,57, 0.8);
  cursor: pointer;
  text-align: center;
  font-weight: 300;
  opacity: .8;
  transition: all 1s;
}
.info-cards:hover{
  transform: scale(1.1);
  color: #DE1F38;
  opacity: 1;
  transition: all 1s;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  color: #CFE6FC;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
  z-index: 2;
}
.content .heading {
  font-weight: 100;
  font-size: 32px;
}
.content .para {
  line-height: 1.5;
}
.content .btn {
  color: #CFE6FC;
  text-decoration: none;
  padding: 10px;
  font-weight: 300;
  border: none;
  cursor: pointer;
  background: linear-gradient(-45deg, #DF1E36 0%, #2AB0E9 100% );
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 3;
}
.card-info::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(-25deg, #DF1E36 0%, #2AB0E9 100% );
  z-index: 1;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
  filter: blur(10px);
}
.card-info:hover::before {
  height: 100%;
  /* filter: blur(10px); */
}
.card-info:hover { 
  box-shadow: none;
} 
.card-info:hover .btn {
  color: #CFE6FC;
  background: #474F57;
}
.content .btn:hover {
  outline: 2px solid #CFE6FC;
  background: transparent;
  color: #CFE6FC;
}
.content .btn:active {
  box-shadow: none;
}
.rates th, td{
  font-weight: 300;
  padding: 0.5em 2em 0.5em 1em;
}
.details{
  font-weight: 200;
  transition: all 0.5s;
  color: #CFE6FC;
  margin-top: 10rem;
  margin-bottom: 4rem;
  width: 50vw;
  text-align: left;
}
.trs{
  display: inline;
  height: 2rem;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}
.background-box:hover{
  transform: rotate(-180deg);
}
.login {
  position: relative;
  font-weight: 200;
  background-color: #474F57;
  display: block;
  padding: 1rem;
  max-width: 350px;
  margin-bottom: 4em;
  border-radius: 1rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.login::before{
  content: '';
  top: -0.25em;
  left: -0.25em;
  position: absolute;
  margin: auto;
  z-index: -1;
  width: 350px;
  height: 380px;
  border-radius: 1rem;
  pointer-events: none;
  background: linear-gradient(-25deg, #DF1E36 0%, #2AB0E9 100% );
  filter: blur(20px);
  transition: all 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.login:hover::before{
  transform: rotate(180deg);
}
.login-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-align: center;
  color: #CFE6FC;
}
.reset {
  position: relative;
  font-weight: 200;
  background-color: #474F57;
  display: block;
  padding: 1rem;
  max-width: 350px;
  margin-bottom: 4em;
  border-radius: 1rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.register {
  position: relative;
  font-weight: 200;
  background-color: #474F57;
  display: block;
  padding: 1rem;
  max-width: 350px;
  margin-bottom: 4em;
  border-radius: 1rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.register::before{
  content: '';
  top: -0.25em;
  left: -0.25em;
  position: absolute;
  margin: auto;
  z-index: -1;
  width: 350px;
  height: 500px;
  border-radius: 1rem;
  pointer-events: none;
  background: linear-gradient(-25deg, #DF1E36 0%, #2AB0E9 100% );
  filter: blur(20px);
  transition: all 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.register:hover::before{
  transform: rotate(180deg);
}
.register-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-align: center;
  color: #CFE6FC;
}
.input-container {
  position: relative;
  color: #141618;
}
.input-container input, .login button {
  outline: none;
  border: 1px solid #e5e7eb;
  margin: 8px 0;
}
.input-container input {
  background-color: #fff;
  padding: 1rem;
  padding-right: 3rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 300px;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.input-container textarea {
  padding: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.input-container span {
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  place-content: center;
}
.input-container span svg {
  color: #66727D;
  width: 1rem;
  height: 1rem;
}
.submit {
  display: block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  /* background-color: #DE1F38; */
  color: #CFE6FC;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  width: 100%;
  border-radius: 0.5rem;
  border-color: #CFE6FC;
  text-transform: uppercase;
}
.signup-link {
  margin-top: 0.5em;
  font-weight: 300;
  color: #CFE6FC;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
}
.signup-link a {
  text-decoration: underline;
  color: #DF1E36;
}
.contact{
  position: relative;
  font-weight: 200;
  background-color: #474F57;
  display: block;
  padding: 1rem;
  max-width: 350px;
  margin-bottom: 4em;
  border-radius: 1rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.contact::before{
  content: '';
  top: -0.25em;
  left: -0.25em;
  opacity: 10%;
  position: absolute;
  margin: auto;
  z-index: -1;
  width: 350px;
  height: 400px;
  border-radius: 1rem;
  pointer-events: none;
  background: linear-gradient(-25deg, #DF1E36 0%, #2AB0E9 100% );
  filter: blur(20px);
  transition: all 2s;
}
.contact:hover::before{
  opacity: 100%;
}
.logoabout{
  width: auto;
  height: 10em;
  margin-bottom: 3rem;
  margin-left: 50%;
  transform: translate(-50%);
  filter: drop-shadow(0 0 1rem #282c31);
}
.logoinfo{
  width: auto;
  height: 10em;
  margin-bottom: 3rem;
  margin-left: 50%;
  transform: translate(-50%);
  filter: drop-shadow(0 0 1rem #282c31)
}
.about{
  padding: 0 5rem 0 5rem;
}
.usr-nav {
  text-align: center;
  margin-top: 1rem;
  margin-left: 0rem;
  width: 10rem;
  height: 95vh;
  padding-top: 2rem;
  /* overflow-y: auto; */
  /* background-color: #21ACE7; */
}
.usr-nav::before,
.usr::before{
  content: '';
  opacity: 0%;
  top: -0.25em;
  left: -0.2em;
  position: absolute;
  margin: auto;
  z-index: -1;
  width: 10rem;
  height: 95vh;
  border-radius: 1rem;
  pointer-events: none;
  background-color: #2AB0E9;
  filter: blur(15px);
  transition: all 2s;
}
.usr-nav:hover::before,
.usr:hover::before{
  opacity: 100%;
}
.user-nav-link{
  color: #CFE6FC;
  transition: .7s;
}
.user-nav-link:hover{
  color: #DF1E36;
}
.user-nav-icn{
  height: 3rem;
}
#info-icn{
  height: 2rem;
  margin-bottom: 0.5rem;
}
.nav-txt{
  color: #DF1E36;
  font-weight: 300;
  text-transform: uppercase;
  opacity: 0;
  transition: all 1s;
}
.user-nav-link:hover > .nav-txt{
  opacity: 1;
}
.logo-nav{
  height: 4rem;
  width: 4rem;
}
.usr{
  position: absolute;
  text-align: center;
  right: 1rem;
  margin-top: 1rem;
  width: 10rem;
  height: 95vh;
  padding-top: 2rem;
  /* overflow-y: auto; */
}
.usr-pnl{
  font-size: 0.9rem;
  font-weight: 300;
}
.userbanner{
  font-weight: 400;
  padding: 1em;
  color: #d4e2ee;
  text-align: end;
  position: relative;
  right: 1rem;
  border-radius: 1rem;
  height: 7rem;
  margin-top: 1rem;
  /* margin-left: 11rem; */
  margin-right: 1rem;
}
.userbanner-nav{
  display: none;
  cursor: pointer;
  position: absolute;
  padding-left: 2rem;
  padding-top: 2rem;
  right: 0.5rem;
  top: 0rem;
  border-radius: 1rem;
  width: 34rem;
  height: 7rem;
  z-index: 1;
  text-align: left;
}
.userbanner-nav-behind{
  display: none;
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 0rem;
  border-radius: 1rem;
  width: 34rem;
  height: 7rem;
  z-index: 1;
  text-align: left;
  backdrop-filter: blur(1px);
  background-color: rgba(29,44,57, 0.8);
  z-index: -1;
}
.userbanner-nav-expand{
  display: block;
  animation: banner-expand 0.5s;
}
.userbanner-nav-collapse{
  animation: banner-collapse 0.5s;
}
.userbanner-name{
  max-width: 12rem;
  display: inline-block;
  cursor: pointer;
  margin-right: 0.5rem;
  z-index: 2;
}
.user-banner-btn{
  display: inline-block;
  transition: all 0.5s;
}
.user-banner-btn-expand{
  transform: rotate(90deg);
}
.userbanner-links{
  display: none;
}
.userbanner-links-show{
  display:flex;
  animation: listings-show .75s; 
}
.profile{
  height: 4.75rem;
  width: 4.75rem;
  border-radius: 50%;
}
.user-dash{
  margin-top: 1rem;
  margin-left: 11.5rem;
  margin-right: 1rem;
  transition: all 1s ease;
}
.dash-header{
  margin: 0.5em 0 0.5em 0;
  min-width: max-content;
  height: 11rem;
  background-color: #1d2c39;
  color: #CFE6FC;
  transition: all 1s;
}
.dash-header:hover::before{
  opacity: 100%;
}
.dash-header-content{
  z-index: 4;
  min-width: max-content;
  padding: 0.75rem;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  height: 5.5rem;
  border-radius: .5rem;
}
.dash-header-txt{
  text-align: left;
  font-size: 0.8rem;
  font-weight: 300;
}
.dash-header-num{
  min-width: fit-content;
  text-align: end;
  font-weight: 500;
  font-size: 1.5rem;
  transition: all 1s;
}
.num{
  color: #d50924 !important;
  /* text-shadow: 0 0 20px #d50924; */
}
.total{
  color: rgb(2, 208, 2) !important;
  /* text-shadow: 0 0 20px rgb(2, 208, 2); */

}
.card-subtitle{
  color: #fff !important;
}
.total-balance{
  margin-top: 1rem;
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;
  color: rgb(2, 208, 2) !important;
  /* text-shadow: 0 0 20px rgb(2, 208, 2); */
}
.dash-main{
  margin-top: 1rem;
  margin-bottom: 2rem;
  min-height: 50vh;
  max-height: fit-content !important;
  background-color: #1d2c39;
}
.pending-disclaimer{
  /* margin-top: 2rem; */
  margin-bottom: 1rem;
  color: #d50924;
  font-weight: 400;
}
table{
  width: inherit;
}
.table-main{
  margin-top: 6rem;
}
.table-include{
  margin-top: 2rem;
}
table tbody td{
  overflow-y:auto;
  border: none;
}
table thead th:first-child {
  border-radius: 10px 0 0 0;
}
table thead th:last-child {
  border-radius: 0 10px 0 0;
}
table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}
table tbody tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
}
.dash-element{
  min-height: 40rem;
  min-width: 20rem;
  height: fit-content;
  background-color: #1d2c39;
  /* overflow: auto; */
  margin-bottom: 2rem;
  transition: all 1s ease;
}
.profile-element{
  min-height: 95vh;
  background-color: #1d2c39;
  overflow: auto;
  margin-bottom: 2rem;
  transition: all 1s ease;
}
.credit-element{
  white-space: nowrap;
}
.list-header{
  min-width: 8.5rem;
}
.accordion{
  width: inherit;
  /* max-width: 49vw !important; */
  margin-right: 0 !important;
}
.mobile-main-td{
  padding: 0;
}
.listing-open-fix{
  overflow: hidden;
}
.open{
  width: inherit;
  /* max-width: 49vw !important; */
}
.listing-container{
  max-width: none;
  margin-left: 1rem;
  margin-right: 1rem;
}
.accordion-button{
  /* background-color: #16222c; */
  color: #CFE6FC !important;
  transition: all 0.75s ease;
}
.accordion-button::after{
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
.accordion-button-bg{
  background-color: #2AB0E9;
}
.accordion-collapse{
  /* animation: listing 0.75s ease; */
  background-color:  #66727D;
  /* background: linear-gradient(to bottom right, #5C6670, #9BACBD, #474F57); */
  background-image: url('./images/listingbg.png');
  background-repeat: no-repeat;
  background-size: cover;
  transition: all .75s ease;
  padding: 0;
}
.accordion-body{
  padding-left: 0 !important;
  padding-right: 0 !important;

}
.listing-expand{
  animation: listing 0.5s;
}
.listing-collapse{
  animation: listing-reverse 0.5s;
}
.listing-img{
  /* display: none; */
  backdrop-filter: blur(3px);
  margin-top: .2rem;
  height: fit-content;
  width: fit-content;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 1s;
  border-radius: 10px;
  padding: 0;
}
.listing-img:hover{
  background-color: rgba(0, 0, 0, 0.8);
  transform: scale(1.05);
}
.col-details{
  margin-left: 2rem;
  margin-right: 2rem;
}
.details-row{
  border-bottom: rgba(0, 0, 0, 0.3) 0.5px solid;
}
.status{
  width: 6rem;
  margin-top: 0.5rem;
  text-align: center;
  border-radius: 20px;
  padding: 0.5rem;
}
.status-active{
  background-color: green;
}
.status-pending{
  background-color: orange;
}
.status-sold{
  background-color: #d50924;
}
.status-unsold{
  background-color: blueviolet;
}
.status-canceled{
  background-color: darkmagenta;
}
.listing-details{
  border-radius: 10px;
  display: none;
  backdrop-filter: blur(3px);
  box-shadow: 2px 7px 15px -2px rgb(8, 19, 30);
  background-color: rgba(0, 0, 0, 0.6);
}
.listing-header{
  color: #d4e2ee;
  font-size: 1em;
  font-weight: 400;
}
.listing-body{
  color: #ffffff;
  font-weight: 300;
  font-size: 1.2em;
}
.listing-show{
  display: block;
}
.col-sm-6{
  max-width: 210px !important;
}
.time-left{
  font-weight: 500 !important;
  color: #d50924 !important;
}
.price{
  font-weight: 500 !important;
  color: rgb(2, 208, 2) !important;
}
.active-content-main{
  margin-left: 1rem;
  margin-right: 1rem;
}
.product-img{
  max-height: 15rem;
  width: inherit;
  height: inherit;
  max-width: 20vw;
  border-radius: 5px;
}
.product-img-active{
  height: 17rem;
  width: inherit;
  border-radius: 5px;
}
.col-details{
  margin-left: 0;
}
.view-image{
  height: 100vh;
  padding: 4rem;
  margin-top: 1rem;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 5rem;
}
.image-full-screen{
  /* width: 70vw; */
  height: 80vh;
}
.open-image{
  position: absolute;
  z-index: 1;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.image-close{
  color: #d4e2ee;
  position: absolute;
  cursor: pointer;
  right: 15rem;
  background-color: #282c31;
}
.image-close:hover{
  color: #d50924;
}

.image-fullscreen{
  z-index: 10;
  position: absolute;
  padding: 5rem;
  left: -5rem;
  width: 90vw;
  height: 90vh;
}
.search {
  position: relative;
  float: right;
  margin: 1rem;
  background: none;
  border: none;
  outline: none;
  max-width: 400px;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 9999px;
  box-shadow: inset 2px 5px 10px rgb(5, 5, 5);
  color: #CFE6FC;
}
.clear-search{
  position: absolute;
  right: 3rem;
  top: 7.75rem;
  transition: all .4s;
}
.clear-search:hover{
  cursor: pointer;
  transform: rotate(180deg);
}
.list-buttons{
  text-align: center;
}
.dash-list {
  margin: 0.5rem;
  min-width: 11rem;
  max-width: 13rem;
  padding-left: 33px;
  padding-right: 33px;
  padding-bottom: 16px;
  padding-top: 16px;
  border-radius: 1rem;
  background: transparent;
  color: #CFE6FC;
  border: none;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
}
.dash-list:hover {
  box-shadow: #2AB0E9 0px 30px 80px -15px, 
              #2AB0E9 0px -2px 20px 0px inset;
}
.dash-list:active {
  transform: scale(0.97);
  box-shadow: #2AB0E9 0px 30px 80px -15px, 
              #2AB0E9 0px -2px 20px 0px inset;
}
.btn-active {
  box-shadow: #2AB0E9 0px 20px 80px -20px, 
              #2AB0E9 0px -2px 20px 0px inset;
}
.googlebtn {
  display: flex;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  align-items: center;
  color: rgb(65, 63, 63);
  background-color: #fff;
  cursor: pointer;
  transition: all .6s ease;
}
.googlebtn:hover{
  background-color: #fff;
  transform: scale(1.01);
}
.googlebtn svg {
  height: 20px;
  margin-right: 1.75rem;
}
.fbbtn {
  display: flex;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  align-items: center;
  color: rgb(65, 63, 63);
  background-color: #1778f2;
  border-color:#1778f2 !important;
  cursor: pointer;
  transition: all .6s ease;
}
.fbbtn:hover{
  background-color: #1778f2;
  transform: scale(1.01);
}
.fbbtn svg {
  color: #ffffff;
  height: 20px;
  margin-right: 1.75rem;
}
#error{
  color: #DF1E36;
  font-weight: 400;
  font-size: small;
  margin: 0.5rem 0 0.5rem 0;
}
.profile-page-img{
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
}
.profile-name{
  font-weight: 300;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.profile-info-container{
  margin-top: 1em;
}
.profile-info-box{
  background-color: #16222c;
  border-radius: 1rem;
  padding: 2rem;
}
.profile-info-box-mobile{
  display: none;
}
.profile-info{
  color: #ffff;
  padding: 1rem;
  margin: 2px;
  font-weight: 300;
  /* box-shadow: 2px 7px 15px -2px rgb(8, 19, 30); */
  /* background-color: rgba(255,255,255, 0.1); */
  border-radius: 10px;
  text-align: left;
  transition: all 1s;
  border: none;
}
.profile-info:hover{
  background-color: rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}
.balances{
  margin-top: 2rem;
}
.credit-box{
  box-shadow: 2px 7px 15px -2px rgb(8, 19, 30);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  text-align: center;
  height: 6.7rem;
  width: inherit;
  margin-top: 1rem;
  transition: all 1s;
}
.credit-box:hover{
  background-color: rgba(0, 0, 0, 0.5);
  transform: scale(1.05);
}
.credit-header{
  background-color: rgba(0, 0, 0, 0.1);
  color: #d4e2ee;
  font-size: 1.1em;
  font-weight: 400;
  white-space: nowrap;
}
.credit-body{
  color: #ffffff;
  font-weight: 300;
  font-size: 1.3em;
}
.request-box{
  margin-top: 1rem;
  padding: 1rem;
}
.request-box > * {
  margin-top: 0.25rem;
}
.request-confirm{
  border: #d4e2ee 1px solid;
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 1rem;
}
.payout-box{
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 5rem;
  bottom: 2rem;
  overflow: scroll;
}
.payout-elements{
  color: #ffff;
  padding: 1rem;
  margin: 5px 2px 5px 2px;
  font-weight: 300;
  box-shadow: 2px 7px 15px -2px rgb(8, 19, 30);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  transition: all 0.5s;
  white-space: nowrap;
}
.payout-elements:hover{
  background-color: rgba(0, 0, 0, 0.5);
  transform: scale(1.02);
}
.payout-title{
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden !important;
  /* margin-bottom: 0.5rem; */
}
.payout-sale{
  font-weight: 500;
  color: #DF1E36;
}
.payout-final{
  font-weight: 500;
  color: rgb(2, 208, 2) !important;
}
.btn-settings{
  margin: 0.25rem;
  border: none;
  box-shadow: none;
  background: none;
  color: #CFE6FC;
  outline: solid 1px #CFE6FC;
  border-radius: 0.5rem;
  height: 2.5rem;
  width: 15rem;
  text-decoration: none;
}
.btn-settings:disabled{
  color: #66727D;
}
.btn-settings:hover{
  background: #CFE6FC !important;
  color: #474F57 !important;
}
.btn-settings:active{
  border: none;
  box-shadow: none;
  background: #CFE6FC !important;
  color: #474F57 !important;
  outline: solid 1px #CFE6FC;
  border-radius: 0.75rem;
  height: 2.5rem;
  width: 15rem;
}
.btn-settings:focus{
  border: none;
  box-shadow: none;
  background: #CFE6FC !important;
  color: #474F57 !important;
  outline: solid 1px #CFE6FC;
  border-radius: 0.75rem;
  height: 2.5rem;
  width: 15rem;
}
.btn-delete{
  margin: 0.25rem;
  border: none;
  box-shadow: none;
  background: none;
  color: #d50924;
  outline: solid 1px #d50924;
  border-radius: 0.5rem;
  height: 2.5rem;
  width: 15rem;
  text-decoration: none;
}
.btn-delete:hover{
  background: #d50924 !important;
  color: #d4e2ee !important;
}
.btn-delete:active{
  border: none;
  box-shadow: none;
  background: #d50924 !important;
  color: #d4e2ee !important;
  outline: solid 1px #d50924;
  border-radius: 0.75rem;
  height: 2.5rem;
  width: 15rem;
}
.btn-delete:focus{
  border: none;
  box-shadow: none;
  background: #d50924 !important;
  color: #474F57 !important;
  outline: solid 1px #d50924;
  border-radius: 0.75rem;
  height: 2.5rem;
  width: 15rem;
}
.settings{
  margin-top: 1rem;
  padding: 1rem;
}
.settings-input{
  padding: 1rem;
  opacity: 0.9;
}
.settings-submit{
  margin-left: 1rem;
  border: solid 1px;
  transition: all 0.2s;
}
.settings-submit:hover{
  margin-left: 1rem;
  border: none;
}
.profile-settings-img{
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  margin-bottom: 1rem;
}
.profile-settings-img:hover{
  cursor: pointer;
}
.popup{
  z-index: 2;
  color: #d4e2ee;
  position: relative;
  height: 28rem;
  width: 50vw;
  background-color: #1d2c39;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #141618e0;
}
.closeModal{
  color: #CFE6FC;
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.closeModal:hover{
  color: #2AB0E9;
}
.upload-title{
  text-align: center;
  color: #CFE6FC;
  font-size: 2rem;
  font-weight: 500;
  margin-top: 4rem;
}
.upload-img{
  background-color: #ffffff;
  width: 20rem;
  height: 5rem;
  border-radius: 10px;
  vertical-align: middle;
  padding-top: 1.75rem;
  padding-left: 1rem;
  margin-top: 5rem;
}
input::file-selector-button {
  border-radius: 5px;
  border: 1px solid #CFE6FC;
  background-color: #CFE6FC;
}
input::file-selector-button:hover {
  transform: scale(1.02);
  background-color: #2AB0E9;
  cursor: pointer;
}
input::file-selector-button:active {
  transform: scale(0.98);
  background-color: #2AB0E9;
  cursor: pointer;
}
.sort-btn{
  background: none;
  border: none;
}
.sort{
  height: 1.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
}
.include{
  padding: 0.5rem;
  /* margin-top: 0.5rem; */
  cursor: pointer;
  background-color: rgb(5, 5, 5, 0.2);
  border-radius: 5px;
}
.include-checkbox{
  margin-right: 0.5rem;
  cursor: pointer;
}
.include-label{
  cursor: pointer;
  font-weight: 200;
}
.comment{
  white-space: wrap;
}
.btn-user-action{
  border: none;
  box-shadow: none;
  background: none;
  color: #CFE6FC;
  outline: solid 1px #CFE6FC;
  border-radius: 0.5rem;
  height: 2.5rem;
  width: inherit;
  text-decoration: none;
}
.btn-user-action:hover{
  background: #CFE6FC !important;
  color: #474F57 !important;
}
.btn-user-action:active{
  border: none;
  box-shadow: none;
  background: #CFE6FC !important;
  color: #474F57 !important;
  outline: solid 1px #CFE6FC;
  border-radius: 0.75rem;
  height: 2.5rem;
  width: inherit;
}
.btn-user-action:focus{
  border: none;
  box-shadow: none;
  background: #CFE6FC !important;
  color: #474F57 !important;
  outline: solid 1px #CFE6FC;
  border-radius: 0.75rem;
  height: 2.5rem;
  width: inherit;
}
.error{
  font-weight: 300;
  color:#d50924;
}
.td-hidden{
  transition: all 1s;
  display: none;
}
td{
  transition: all 1s;
}
.tos{
  margin-top: 2rem;
  padding: 0 10rem 0 10rem;
  font-weight: 300 !important;
}
.tos-check{
  margin-left: 1rem;
  color: #d4e2ee;
}
.popup-input{
  margin: 1rem 0 1rem 0;
  width: 20rem;
}
.hidden{
  display: none !important;
}
.verify{
  cursor: pointer;
  text-decoration: underline;
  color: #2AB0E9;
}
.userinfo{
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  min-height: 50vh;
  background-color: #1d2c39;
  font-weight: 300;
}
.user-info-table{
  max-width: 40rem;
}
.agree{
  margin-left: 1rem;
  font-size: large;
  font-weight: 500;
  color: #d50924;
}
.mobile-banner{
  display: none;
}
  

/**********************!!!!!!!!  Main Styling for Mobile devise below !!!!!!!!!!**********************/
/**************** Small devices such as phones ( below 640px) ***************/
@media only screen and (max-width: 48.5em) {
  .popup{
    width: 90vw;
    height: max-content;
    padding: 1rem;
  }
  .userbanner{
    display: none;
  }
  body {
    width: 100%;
    min-height: 100vh;
  }
  h2{
    text-align: center;
    font-weight: 400 !important;
  }
  .navcontainer{
    display: none;
  }
  .navbar{
    display: none;
  }
  .navbtn{
    display: none;
  }
  .navimg{
    display: none;
  }
  .home{
    margin-top: 4rem;
    padding: 2.5em;
  }
  .home-header {
    font-weight: 300;
    text-align: center;
    margin: 1rem 0 1rem 0;
  }
  .info-header{
    text-align: left;
  }
  .logohome{
    width: auto;
    height: 5em;
    margin-left: 2em;
    filter: drop-shadow(0 0 4px #282c31);
    animation: none;
  }
  .x{
    height: 2em;
    margin-top: 1.5em;
    margin-bottom: 5em;
    margin-left: 1.5em;
    margin-right: -0.5em;
    filter: drop-shadow(0 0 4px #282c31);
    animation: none;
  }
  .ebay{
    margin-top: 1em;
    height: 2.5em;
    filter: drop-shadow(0 0 4px #282c31);
    animation: none;
  }
  .socials{
    height: 25px;
    width: 25px;
    margin: 1em;
    fill: #CFE6FC;
    transition: all 0.5s;
  }
  .mobile-navbar{
    margin-left: 0rem;
    z-index: 7;
    position: fixed;
    display: block;
    background-color: rgba(0, 0, 0, 0.6);
    width: 80vw;
    height: 100%;
    top: 0;
    backdrop-filter: blur(5px);
    transition: all .5s;
    padding-top: 5rem;
    transform: translate(-25rem, -25rem);
  }
  .mobile-navbar-fold{
    height: 30rem;
    width: 30rem;
    border-radius: 100%;
    transform: translate(-30rem, -22rem);
  }
  .mobile-navbar-expand{
    animation: animation-mobile-nav 0.5s;
    height: 100%;
    transform: translate(0rem, 0rem);
    border-radius: 0 20px 20px 0;
  }
  .mobile-navbar-expand #mobile-navbar-link-1{
    opacity: 1;
    animation: links-animation 0.55s;
  }
  .mobile-navbar-expand #mobile-navbar-link-2{
    animation: links-animation 0.65s;
    /* animation-delay: 0.2s; */
    opacity: 1;
  }
  .mobile-navbar-expand #mobile-navbar-link-3{
    animation: links-animation 0.75s;
    /* animation-delay: 0.4s; */
    opacity: 1;
  }
  .mobile-navbar-expand #mobile-navbar-link-4{
    animation: links-animation 0.85s;
    /* animation-delay: 0.6s; */
    opacity: 1;
  }
  .mobile-navbar-expand #mobile-navbar-link-5{
    animation: links-animation 0.95s;
    /* animation-delay: 0.8s; */
    opacity: 1;
  }
  .mobile-navbar-expand #mobile-navbar-link-6{
    animation: links-animation 1s;
    /* animation-delay: 0.8s; */
    opacity: 1;
  }
  .mobile-navbar-expand #mobile-navbar-link-7{
    animation: links-animation 1.05s;
    /* animation-delay: 0.8s; */
    opacity: 1;
  }
  .mobile-navbar-link{
    z-index: 10;
    margin: 2rem 0 2rem 1rem;
    color: #CFE6FC;
    font-size: 2rem;
    font-weight: 200;
    opacity: 0;
  }
  .link-usr-nav{
    margin: 1rem 0 1rem 1rem;
  }
  .mobile-navbar-link:active{
    color: #d50924;
  }
  .mobile-navbar-icn{
    margin-top: 0.5rem;
    position: absolute;
    left: 3rem;
    height: 2rem;
    margin-right: 2rem;
  }
  #info-icn{
    margin-top: 0.5rem;
    position: absolute;
    margin-top: 10px;
    left: 3rem;
    height: 1.5rem;
  } 
  .mobile-navbar-usr{
    padding-top: 3rem;
    margin-left: -1rem;
  }
  .mobile-nav{
    transition: all 1s;
    transform: translate(0,0);
    z-index: 9;
    display: block;
    position: fixed;
    top: 10px;
    left: 5px;
    width: 50px;
    height: 50px;
    overflow: hidden;
    font-family: 'Open Sans', Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .mobile-nav-slide{
    transition: all 1s;
    transform: translate(0, -5rem);
  }
  .mobile-nav-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  #mobile-nav-container{
    cursor: pointer;
  }
  [class^=line-]{
    position: relative;
    width: 3rem;
    background-color: #CFE6FC;
    height: 0.25rem;
    border-radius: .75rem;
    margin: 0.5rem 0 0.5rem 0;
    box-shadow: 0.5px 1px 7px 0px rgba(0,0,0,0.4);
    transition: 0s ease-in-out all;
  }
  .line-1{
    display: block;
    z-index: 5;
  }
  .line-2{
    display: block;
    z-index: 2;
  }
  .line-3{
    display: block;
    z-index: -1;
  }
  .mobile-btn-info{
    margin-top: 4rem;
    width: 200px;
  }
  .info-btn{
    font-size: 12px;
    min-width: max-content;
    margin-top: 1em;
    margin-bottom: 2rem;
    height: 3em;
  }
  .home-text{
    font-size: 1em;
  }
  .userinfo{
    padding: 0.5rem;
  }
  .card-info {
    font-weight: 200;
    margin-top: 7rem;
    margin-bottom: 2rem;
    width: 90vw;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    overflow: none;
    border-radius: 1rem;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
  }
  .rates th, td{
    font-weight: 300;
    padding: 0.5em 0em 0.5em 0em;
  }
  .details{
    margin-top: 5rem;
    font-weight: 300;
    margin-bottom: 4rem;
    width: 90vw;
    text-align: left;
  }
  .card{
    border-radius: 1.5rem;
    box-shadow: 2px 7px 15px -2px rgb(8, 19, 30);
    background-color: #16222c;
    color: #CFE6FC;
  }
  .info-cards{
    border-radius: 10px;
    background-color: rgba(29,44,57, 0.8);
    width: 80vw;
    margin: 1rem 0 1rem 0;
    cursor: pointer;
    text-align: center;
    font-weight: 300;
    opacity: .8;
    transition: all 1s;
  }
  .info-cards:hover{
    transform: scale(1.1);
    color: #DE1F38;
    opacity: 1;
    transition: all 1s;
  }
  .background-box:hover{
    transform: rotate(-180deg);
  }
  .login {
    margin-top: 8rem;
  }
  .register {
    margin-top: 8rem;
  }
  .contact{
    margin-top: 8rem;
  }
  .logoabout{
    margin-top: 8rem;
  }
  .about{
    text-align: left;
    padding: 0;
  }
  .usr-nav {
    display: none;
  }
  .usr{
    display: none;
  }
  .pending-disclaimer{
    margin-top: 0;
  }
  .table-main{
    display: none;
  }
  .table-include{
    display: none;
  }
  table thead th:first-child {
    border-radius: 10px 0 0 0 !important;
  }
  table thead th:last-child {
    border-radius: 0 10px 0 0 !important;
  }
  table tbody tr:last-child td:first-child {
    border-radius: 0 0 0 10px !important;
  }
  table tbody tr:last-child td:last-child {
    border-radius: 0 0 10px 0 !important;
  }  
  .table-mobile{
    margin-top: 6rem;
    display: block;
  }
  .listing-details-mobile{
    border-radius: 10px;
    display: block;
    backdrop-filter: blur(3px);
    box-shadow: 2px 7px 15px -2px rgb(8, 19, 30);
    background-color: rgba(0, 0, 0, 0.6);
    /* width: inherit; */
    min-width: 18rem;
    max-width: 70vw;
  }
  .product-img-mobile{
    height: inherit;
    width: inherit;
    max-width: 70vw;
    max-height: 18rem;
    border-radius: 5px;
  }
  .mobile-main-td{
    width: 20rem;
  }
  .user-dash{
    margin: 2rem 0 0 0;
  }
  .dash-element{
    height: 35rem;
    min-width: 20rem;
    width: 100%;
  }
  .settings-element{
    height: 48rem;
  }
  .dash-header{
    position: relative;
    box-sizing: border-box;
    min-width: 15rem;
    width: 100%;
  }
  .dash-main{
    margin-top: 1rem;
    min-height: 40rem;
  }
  .card-title{
    text-align: left;
  }
  .dash-list{
    width: 30rem;
  }
  .search{
    width: 80vw;
  }
  .clear-search{
    top: 412px;
  }
  .col-details{
    margin-left: 4rem;
  }
  .table-listings-mobile{
    min-width: 50rem;
  }
  .listing-img-mobile{
    /* display: none; */
    backdrop-filter: blur(3px);
    margin-top: .2rem;
    width: fit-content;
    max-width: 80vw;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 1s;
    border-radius: 10px;
    padding: 0;
  }
  .listing-container-mobile{
    width: inherit;
  }
  .view-image{
    margin: 0rem;
  }
  .image-full-screen{
   max-width: 95vw;
   height: auto;
   max-height: 80vh;
  }
  .image-close{
   right: 1rem; 
  }
  .listing-img:hover{
    background-color: rgba(0, 0, 0, 0.8);
    transform: scale(1.05);
  }
  .listing-details{
    border-radius: 10px;
    backdrop-filter: blur(3px);
    box-shadow: 2px 7px 15px -2px rgb(8, 19, 30);
    background-color: rgba(0, 0, 0, 0.6);
    width: 18rem;
    margin-top: 1rem;
  }
  .listing-header{
    color: #d4e2ee;
    font-size: 1em;
    font-weight: 400;
  }
  .listing-body{
    color: #ffffff;
    font-weight: 300;
    font-size: 1.1em;
  }
  .listing-show{
    /* transition: all 1s; */
    display: block;
    /* animation: listings-show .25s;  */
  }
  .box-active-mobile{
    display: block !important;
    margin: 0 1rem 0 1rem;
  }
  .credit-element{
    height: 40rem;
  }
  .profile-info-box{
    display: none;
  }
  .profile-page-img{
    margin-left: 50%;
    transform: translate(-50%);
  }
  .profile-info-box-mobile{
    margin-top: 2rem;
    display: block;
  }
  .profile-settings-img{
    margin-left: 50%;
    transform: translate(-50%);
  }
  .settings{
    text-align: center;
  }
  .settings-input{
    min-width: 12rem;
    text-align: left;
    margin-top: 0;
  }
  .form-label{
    font-weight: 300;
  }
  .settings-element{
    height: fit-content;
  }
  .update-buttons{
    margin-top: 2rem;
  }
  .pagination{
    margin-top: 1rem;
    min-width: 91vw;
    display: flex;  
    flex-wrap: wrap;
    justify-content: center;
  }
  .mobile-banner{
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
  }
  .mobile-banner-logo{
    height: 4rem;
    filter: drop-shadow(0 2px 5px #282c31);
  }
}


/* Medium devices such as tablets (768px and up) */
@media only screen and (min-width: 48.5em) and (max-width: 87.5em) {
  .listing-details{
    border-radius: 10px;
    backdrop-filter: blur(3px);
    box-shadow: 2px 7px 15px -2px rgb(8, 19, 30);
    background-color: rgba(0, 0, 0, 0.6);
  }
  .userbanner{
    display: block;
  }
  .table-mobile{
    display: none;
  }
  .mobile-nav{
    display: none;
  }
  .mobile-navbar-icn{
    display: none;
  }
  .mobile-navbar-link{
    display: none;
  }
  .usr{
    display: none;
  }
  .user-dash{
    margin-top: 1rem;
    margin-left: 11.5rem;
    margin-right: 1rem;
    transition: all 1s ease;
  }
  .navcontainer{
    width: 50em;
  }
  .navbtn{
    width: 8.5em;
  } 
  .details{
    width: 70vw;
  }
  .card-info {
    width: 35em;
  }
  .info-cards{
    width: 15rem;
    margin-bottom: 2rem;
  }
  .box-active-mobile{
    display: flex;
    margin: 0 1rem 0 1rem;
  }
  .col-details{
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .view-image{
    margin: 0rem 0rem 0rem 10rem;
  }
  .image-full-screen{
   max-width: 70vw;
   height: auto;
   max-height: 80vh;
  }
  .image-close{
   right: 1rem; 
  }
  .table-main{
    display: block;
    margin-top: 6rem;
  }
  .table-include{
    display: block;
    margin-top: 2rem;
  }

}

@media only screen and (min-width: 48em) and (max-width: 1049px) {
  .dash-list{
    width: 20rem;
  }
  .dash-main{
    min-height: 30rem;
  }
  
}

@media only screen and (min-width: 506px) and (max-width: 536px) {
  .clear-search{
    top: 16.75rem !important; 
  }
}


@media only screen and (min-width: 538px) and (max-width: 776px) {
  .clear-search{
    top: 16.75rem !important;
  }
  
}

@media only screen and (min-width: 769px) and (max-width: 929px) {
  .clear-search{
    top: 16.75rem !important;
  }
}

@media only screen and (min-width: 930px) and (max-width: 1223px) {
  .clear-search{
    top: 12.25rem !important;
  }
}


@media only screen and (min-width: 769px) and (max-width: 1028px) {
  .listing-container{
    min-width: 60rem;
  }
  .table-main{
    max-width: inherit;
    overflow-y: auto;
  }
  .table-include{
    max-width: inherit;
    overflow-y: auto;
  }
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 87.5em) {
  .listing-details{
    border-radius: 10px;
    backdrop-filter: blur(3px);
    box-shadow: 2px 7px 15px -2px rgb(8, 19, 30);
    background-color: rgba(0, 0, 0, 0.6);
  }
  .table-mobile{
    display: none;
  }
  .mobile-nav{
    display: none;
  }
  .mobile-navbar-icn{
    display: none;
  }
  .mobile-navbar-link{
    display: none;
  }
  .userbanner{
    display: block;
  }
  .usr{
    display: none;
  }
  .box-active-mobile{
    display: flex;
    margin: 0 1rem 0 1rem;
  }
  .col-details{
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .table-main{
    max-width: inherit;
    overflow-y: auto;
  }
  .table-include{
    max-width: inherit;
    overflow-y: auto;
  }
}


/* Animation and Keyframes */
.mobile-nav-active > .line-1  {
  animation: animation-1 0.75s;
  transform: translate(0, .75rem) rotate(135deg);
}
.mobile-nav-active > .line-3 {
  animation: animation-3 0.75s;
  transform: translate(0, -.75rem) rotate(45deg); 
}
.mobile-nav-active > .line-2  {
  animation: animation-2 0.75s;
  opacity: 0;
}
.mobile-nav-default > .line-1 {
  animation: reverse-animation-1 0.75s;
}
.mobile-nav-default > .line-2 {
  animation: reverse-animation-2 0.75s;
}
.mobile-nav-default > .line-3 {
  animation: reverse-animation-3 0.75s;
}
@keyframes animation-1{
  0%{
    transform: translate(0,0) rotate(0);
  }
  50%{
    transform: translate(0, 0.75rem) rotate(0);
  }
  100%{
    transform: translate(0, 0.75rem) rotate(135deg);
  }
}
@keyframes animation-2{
  0%{
    opacity: 100%;
  }
  50%{
    transform: scale(0.5);
    opacity: 0%;
    
  }
  100%{
    opacity: 0;
  }
}
@keyframes animation-3{
  0%{
    transform: translate(0,0) rotate(0);
  }
  50%{
    transform: translate(0, -0.75rem) rotate(0);
  }
  100%{
    transform: translate(0, -0.75rem) rotate(45deg);
  }
}

@keyframes reverse-animation-1 {
  0%{
    transform: translate(0, 0.75rem) rotate(135deg);
  }
  50%{
    transform: translate(0, 0.75rem) rotate(0);
  }
  100%{
    transform: translate(0,0) rotate(0);
  }
}
@keyframes reverse-animation-2{
  0%{
    opacity: 0%;
  }
  50%{
    transform: scale(0.5);
    opacity: 0%;
  }
  100%{
    opacity: 100%;
  }
}
@keyframes reverse-animation-3{
  0%{
    transform: translate(0, -0.75rem) rotate(45deg);
  }
  50%{
    transform: translate(0, -0.75rem) rotate(0);
  }
  100%{
    transform: translate(0,0) rotate(0);
  }
}
@keyframes links-animation {
  0%{
    opacity: 0;
  }
  75%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
  
}
@keyframes spread1 {
  from {
    margin-left: 0;
  }
  to {
    margin-left: 4em;
  
  }
}
@keyframes spread2 {
  from {
    margin-left: 0;
    margin-right: 0;
  }
  to {
    margin-left: 3em;
    margin-right: 1em;
  }
}
@keyframes spread3 {
  from {
    margin-left: 0;
    margin-right: 0;
  }
  to {
    margin-left: 2em;
    margin-right: 1em;
  
  }
}
@keyframes sh02 {
  from {
    opacity: 0.3;
    left: 0%;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0.1;
    left: 100%;
  }
}
@keyframes listings-show {
  from{
    opacity: 0;
  }
  to{
    opacity: 100%;
  }
}
@keyframes listing {
  from{
    width: 30vw;
  }
  to{
    width: 100%;
  }
}
@keyframes listing-reverse {
  from{
    width: 100%;
  }
  to{
    width: 30vw;
  }
}
@keyframes banner-expand {
  from{
    width: 0%;
  }
  to{
    width: 32rem;
  }
}
@keyframes banner-collapse {
  from{
    width: 32rem;
  }
  to{
    width: 0%;
  }
}



  /* Largest devices such as desktops (1280px and up) */
/* @media only screen and (min-width: 80em) {
  .mobile-nav{
    display: none;
  }
  .mobile-navbar-icn{
    display: none;
  }
  .mobile-navbar-link{
    display: none;
  }
  .userpanel-mobile{
    display: none;
  }
} */


/* Small devices such as large phones (640px and up) */
/* @media only screen and (min-width: 40em) and (max-width: 48em) {
  .userpanel-mobile{
    display: none;
  }
  .usr{
    display: none;
  }
  
} */